/* src/styles/Dashboard.css */

/* Reset de margens e preenchimentos */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mr-1 {
    /* color: pink; */
    margin: 0 auto -17px 32px;

}

.project-name-dash{
    margin: 14px 0 14px 18px;
}

.project-address-dash {
    margin: 10px 0 25px 0;
    color: #a7a7a7;
    font-size: 14px;
    margin: 14px 0 34px 18px;
}

.details-dash{
    background-color: #6257DD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 0 25px 38%;
}

.status {
    margin: -27px 0 48px 18px;
}

.h3-name {
    margin: 38px auto 0 14px;
}

/* Estilo da barra de navegação */
.menu {
    width: 200px;
    height: 100vh; /* Altura da tela inteira */
    background-color: #f4f4f4; /* Cor de fundo neutra */
    color: #333; /* Cor do texto */
    padding: 20px;
    font-family: Arial, sans-serif; /* Fonte mais sofisticada */
    border-right: 1px solid #ddd; /* Adiciona uma borda à direita */
    display: flex; /* Adiciona display flex */
    flex-direction: column; /* Define a direção como coluna */
}

.menu-open {
    background-color: #f4f4f4; /* Altera a cor de fundo quando o menu está aberto */
}

.menu h2 {
    font-size: 1.5rem; /* Tamanho de fonte maior */
    margin-bottom: 20px; /* Espaçamento inferior */
}

.menu-icon {
    display: none; /* Oculta o ícone de menu hamburguer por padrão */
    cursor: pointer;
}

.menu-list {
    list-style-type: none;
    padding: 0;
    display: flex; /* Adiciona display flex */
    flex-direction: column; /* Define a direção como coluna */
}

.menu li {
    margin-bottom: 10px; /* Espaçamento entre os itens do menu */
    display: flex; /* Adiciona display flex */
    align-items: center; /* Alinha verticalmente */
}

.menu a {
    text-decoration: none;
    color: #666; /* Cor do link */
    transition: color 0.3s; /* Transição suave de cor */
    padding: 0 0 0 10px;
}

.menu a:hover {
    color: #000; /* Cor do link ao passar o mouse */
}

/* Estilo do conteúdo */
.content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: row; /* Organiza o conteúdo em linha */
    justify-content: center; /* Alinha o conteúdo ao centro horizontalmente */
    align-items: flex-start; /* Alinha o conteúdo ao topo */
}

.trash-icon{
    color: gray;
}

.project-list {
    list-style: none;
    padding: 0;
    margin-left: 20px; /* Adiciona margem à esquerda para separar do menu */
}

.project-list li {
    margin-bottom: 10px;
}

.project-list li a {
    text-decoration: none;
    color: #333;
}

.avatar-icon {
    margin: 0 0 -6px 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* margin-right: 8px; */
    color: white;
  }

.office-name {
    color: white;
}
  

/* Media query para telas menores */
@media screen and (max-width: 768px) {
    .menu {
        width: 100%; /* Define a largura como 100% */
        background-color: white;
        border-right: none; /* Remove a borda direita */
    }

    .p-2{
        padding: 0.5rem;
        margin: 0 0 0 0;
    }

    .menu h2 {
        text-align: center; /* Alinha o título ao centro */
        margin-bottom: 10px; /* Reduz o espaçamento inferior */
    }

    .menu-icon {
        display: block; /* Exibe o ícone de menu hamburguer */
        align-self: flex-end; /* Alinha o ícone à direita */
    }

    .menu-list {
        display: none; /* Oculta a lista de navegação por padrão */
        background-color: pink;
    }

    .show {
        display: flex; /* Exibe a lista de navegação quando a classe show é aplicada */
        flex-direction: column; /* Define a direção como coluna */
    }

    .menu a {
        padding: 10px; /* Adiciona um espaçamento interno aos links */
    }

    .mr-1 {
        /* color: blue; */
        margin: 0 auto -20px 32px;
    
    }

    .status {
        margin: -22px 0 48px 10px;
    }
    
    .h3-name {
        margin: 38px auto 0 14px;
    }

    .details-dash{
        background-color: #6257DD;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 0 0 25px 35%;
    }
}

/* forgotPasswordPage.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .auth-container {
    width: 90%;
    max-width: 400px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .auth-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .auth-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .auth-button {
    width: 100%;
    padding: 12px;
    background-color: #564AE5;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: #7b74cd;
  }
  
  .notification {
    color: #333;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .auth-container {
      width: 90%;
      max-width: 100%;
    }
  
    .auth-heading {
      font-size: 20px;
    }
  
    .auth-button {
      font-size: 14px;
    }
  }
  
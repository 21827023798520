/* Estilos gerais */
.board {
    display: flex;
    justify-content: center;
    padding: 5%;
}

.column {
    flex-grow: 1;
    background-color: #f4f5f7;
    border-radius: 12px;
    padding: 15px;
    margin: 15px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.column:nth-child(4) {
    order: 1;
}

.column:nth-child(2) {
    order: 3;
}

.column:nth-child(3) {
    order: 2;
}

.column:nth-child(1) {
    order: 4;
}

.button-task {
    width: 100%;
    padding: 12px;
    background-color: #564AE5;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 4%;
    transition: background-color 0.3s ease;
}

.column-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.cards {
    margin-bottom: 15px;
}

.card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.dropdown-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #666;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.dropdown-btn:hover {
    color: #333;
}

.card div {
    margin-bottom: 10px;
}

.card input,
.card textarea {
    width: calc(100% - 40px); /* Ajuste para acomodar o botão dropdown */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    background-color: #f8fafc;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card input:focus,
.card textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0px 0px 8px rgba(74, 144, 226, 0.5);
}

.add-column,
.add-card {
    background-color: #f4f5f7;
    border-radius: 12px;
    padding: 15px;
    margin: 15px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    order: 4;
}

.textarea {
    resize: vertical;
    margin: 0 0 0 0;
    width: 100%;
}

.add-column input,
.add-card input,
.add-card textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    background-color: #f8fafc;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.add-column button,
.add-card button {
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

.add-column button:hover,
.add-card button:hover {
    background-color: #0056b3;
}

.add-card input[type="text"],
.add-card textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 10px;
}

.add-card input[type="text"]:focus,
.add-card textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0px 0px 8px rgba(74, 144, 226, 0.5);
}

.add-card button[type="submit"],
.add-column button[type="submit"] {
    background-color: #4a90e2;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

.add-card button[type="submit"]:hover,
.add-column button[type="submit"]:hover {
    background-color: #0056b3;
}

.input-task {
    width: 100%;
    margin: 3% 0 0% 0;
}

.input-task-title{
    width: 100%;
    margin: 8% 0 0% 0;
}

.date-status{
    font-size: 10px;

}

/* Estilos específicos para smartphones */
@media only screen and (max-width: 768px) {
    .board {
        flex-direction: column;
        align-items: center;
    }

    .column {
        width: 90%;
    }

    .button-task {
        margin-top: 8%;
    }

    .input-task,
    .input-task-title {
        margin-top: 5%;
    }
}

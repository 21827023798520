.inf-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 8px;
  }
  
  .details-container {
    margin-top: 20px;
  }
  
  .details-container p {
    margin-bottom: 10px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .project-details {
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 20px;
    color: #7f7f7f;
  }
  
  .save-button {
    background-color: #6257DD;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .backp-button{
    background-color: #6257DD;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #b7b1ff;
  }
  
  .save-button:active {
    background-color: #b7b1ff;
  }
  
  @media screen and (max-width: 480px) { /* Consulta de mídia para telas de até 480px de largura (tipicamente smartphones) */
 
    .save-button {
      justify-content: center;
      background-color: #6257DD;
      color: white;
      padding: 8px 20px;
      display: flex;
      margin: auto;
      width: 62%;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    
  
  }
  
/* tailwindAuth.css */

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-container {
  width: 90%;
  max-width: 400px; /* Adicionado uma largura máxima para evitar que o contêiner fique muito largo em telas maiores */
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative; /* Mudado para relativo para facilitar o posicionamento */
  margin: auto; /* Centraliza horizontalmente */
}

.auth-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.auth-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.auth-button {
  width: 100%;
  padding: 12px;
  background-color: #564AE5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-button:hover {
  background-color: #7b74cd;
}

.eye-icon-login {
  position: absolute;
  top: 47%;
  right: 47px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey;
}

.auth-link {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #333;
  text-decoration: none;
}

.auth-link:hover {
  text-decoration: underline;
}

/* Ajustes para telas menores */
@media screen and (max-width: 768px) {
  .auth-container {
    width: 90%;
    max-width: 100%; /* Para ocupar toda a largura do dispositivo */
  }

  .auth-heading {
    font-size: 20px; /* Reduzindo o tamanho do título */
    margin-bottom: 15px; /* Reduzindo a margem inferior */
  }

  .auth-button {
    font-size: 14px; /* Reduzindo o tamanho do botão */
  }

  .version-login {
    margin-left: 36%; /* Ajuste fino para o posicionamento */
    font-size: 10px;
    margin-top: -170%; /* Ajuste fino para o posicionamento */
  }
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* App.css */
.App {
  text-align: center;
}

input {
  margin: 5px;
}

button {
  margin: 5px;
}

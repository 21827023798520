.project-details-page {
  padding: 20px;
}

.input-group {
  margin-bottom: 15px;
  display: inline-grid;
  align-items: center;
  padding: 0 10px 0 0;
}

.input-group-color {
  margin-bottom: 15px;
  display: inline-grid;
  align-items: center;
}

.input-group-color input[type="text"] {
  width: 100px; /* Defina o tamanho desejado */
  /* Outros estilos, se necessário */
}

.icon-folder {
  display: inline-block;
  width: 15px; /* Ajuste conforme necessário */
  height: 15px; /* Ajuste conforme necessário */
  background-image: url('../img/add.png');
  background-size: contain; /* Ajusta o tamanho do ícone */
  background-repeat: no-repeat;
  margin-right: 5px; /* Espaçamento entre o ícone e o texto */
  vertical-align: middle; /* Alinha verticalmente com o texto */
}

.input-group-model {
  margin-bottom: 15px;
  display: inline-grid;
  align-items: center;
}

.input-group-model input[type="text"] {
  width: 100px; /* Defina o tamanho desejado */
  /* Outros estilos, se necessário */
}

.input-group-material {
  margin-bottom: 19px;
  padding: 0 6px 0 5px;
  display: inline-grid;
  align-items: center;
}

.input-group-material input[type="text"] {
  width: 220px;
  margin: 0 0px 0 0;
}

.input-group-ambiente {
  margin-bottom: 1%;
  padding: 0 6px 0 3px;
  display: inline-grid;
  align-items: center;
}

.input-group-ambiente input[type="text"] {
  width: 192px;
  margin: 0 0px 0 0;
  padding: 0 0 0 6px;

}


.input-group-cam {
  margin-bottom: 15px;
  /* display: inline-grid; */
  margin: 1px 0 0 0;
  align-items: center;
  padding: 0 0 0 3px;
}

.input-group-ambiente {
  margin-bottom: 1%;
  /* padding: 0 7px 8px 6px; */
  display: inline-grid;
  /* margin: 5px 0 0 0; */
}

.input-group-ambiente select, .input-group-ambiente input[type="text"] {
  height: 40px;
margin-top: 6px;
  border-radius: 4px;

}



.input-group-area {
  margin-bottom: 15px;
  display: inline-grid;
  align-items: center;
}

.input-group-area input[type="text"] {
  width: 146px; /* Defina o tamanho desejado */
  /* Outros estilos, se necessário */
}

.input-group label {
  flex: 0 0 100px; /* Largura fixa para os rótulos */
  margin-right: 10px;
}

.input-group-material select,
.input-group-material input[type="text"] {
  height: 40px; /* Define a altura desejada */
  border-radius: 4px; /* Adiciona border radius */
}

.input-group-ambiente select,
.input-group-ambiente input[type="text"] {
  height: 40px; /* Define a altura desejada */
  border-radius: 4px; /* Adiciona border radius */
}


.input-group input[type="text"],
.input-group select,
.input-group input[type="file"] {
  flex: 1; /* Ocupa o restante do espaço disponível */
  height: 40px; /* Define a altura desejada */
  border-radius: 6px; /* Adiciona border radius */
}

.input-group-brand input[type="text"],
.input-group-brand input[type="text"] {
  flex: 1; /* Ocupa o restante do espaço disponível */
  height: 40px; /* Define a altura desejada */
  border-radius: 6px; /* Adiciona border radius */
}

.input-group-brand {
  margin-bottom: 15px;
  display: inline-grid;
  align-items: center;
}

.input-group-brand input[type="text"] {
  width: 100px; /* Defina o tamanho desejado */
  /* Outros estilos, se necessário */
}

.input-group-model input[type="text"],
.input-group-model input[type="text"] {
  flex: 1; /* Ocupa o restante do espaço disponível */
  height: 40px; /* Define a altura desejada */
  border-radius: 6px; /* Adiciona border radius */
}

.input-group-color[type="text"],
.input-group-color input[type="text"] {
  flex: 1; /* Ocupa o restante do espaço disponível */
  height: 40px; /* Define a altura desejada */
  border-radius: 6px; /* Adiciona border radius */
}

.input-group-area[type="text"],
.input-group-area input[type="text"] {
  flex: 1; /* Ocupa o restante do espaço disponível */
  height: 40px; /* Define a altura desejada */
  border-radius: 6px; /* Adiciona border radius */
}

.add-item-section {
  justify-content: center;
  display: flex;
  padding: 6% 0 2% 0;
}

.input-group select {
  appearance: none;
  outline: none;
  border-radius: 0.375rem; /* Adiciona border radius */
  padding: 0.5rem 1rem; /* Adiciona preenchimento */
  font-size: 1rem; /* Define o tamanho da fonte */
  line-height: 1.5; /* Define a altura da linha */
  border: 1px solid #e2e8f0; /* Adiciona borda */
  background-color: #fff; /* Cor de fundo */
  width: 100%; /* Define a largura como 100% */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.input-group select:focus {
  border-color: #4299e1; /* Cor da borda ao focar */
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); /* Adiciona sombra ao focar */
}

.input-group button {
  background-color: #564AE5;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.input-group button:hover {
  background-color: #463ecc;
}

.paginate-class {
  margin: 3% 0 0 -9.22rem;
}

.items-list {
  margin: 0px 0 0 21.4rem;
  /* padding: 0 6px 0 9%; */
}

.items-list table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 0 -61px;
}

.items-list th,
.items-list td {
  border: 1px solid #ccc;
  padding: 8px 20px 9px 11px;
  width: 12%;
  /* text-align: left; */
}

.items-list th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.items-list img {
  max-width: 90px;
  margin: 0 -87px 0 0;
}

.generate-pdf-button {
  margin-top: 30px;
  text-align: center;
}

.generate-pdf-button button {
  background-color: #564AE5;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.generate-pdf-button button:hover {
  background-color: #7b74cd;
}


.item-details table {
  border-collapse: collapse;
  font-size: 12px;
}

.item-details td {
  border: none;
}

.auth-button-add {
  width: 56px;
  /* padding: 12px; */
  height: 45px;
  margin: 30px 0 0 1%;
  background-color: #564AE5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.purple-button{
  width: 90px;
  /* padding: 12px; */
  height: 45px;
  margin: 0 0 0 1%;
  background-color: #564AE5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.generate-remove {
  margin-top: 21px;
  /* text-align: left; */
  margin-left: 6%;}

.generate-remove button {
  background-color: #564AE5;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.generate-remove button:hover {
  background-color: #7b74cd;
}

.text-filter {
  padding: 28px 0 0 0;
  display: flex;
  justify-content: center;
}

.pdf-filter {
  padding: 50px 0 0 0;
  /* display: flex; */
  margin: 32px 0 0 55rem;
}


.filtrogeral-form {
  margin: 0 0 0 52.29rem;
  display: flex;
  /* width: 38%; */
  display: inline-flex;
  padding: 2% 0 5% 0;
}

.filtrogeral-label {
  margin-right: -76px;
  font-size: 14px;
  color: #4a5568;
  margin-top: -25px;
  padding: 0 0 0 18px;
}

.filtrogeral-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.filtrogeral-select option {
  font-size: 16px;
}





.form-label {
  margin-right: 10px;
  font-size: 14px;
  color: #4a5568;
  margin-top: 12px;
  padding: 0 0 0 18px;
}

/* Estilos para os selects */
.form-select {
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.name-project-memorial {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));}

.form-select:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}



/* Estilos para o botão */
.form-button {
  background-color: #564AE5;
  color: #fff;
  border: none;
  margin: 14px auto 36px 47%;
  /* display: flex; */
  justify-content: center;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #463ecc;
}

.pdfForm{
  margin: 0 1px 2% 33%;
  display: inline-flex;
  padding: 2% 0 0 0;

}


.pagination {
  margin: 0 12.2rem 0 0;
  display: flex;
  justify-content: center;
}

.pagination a {
  color: #4a5568;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.pagination a.active {
  background-color: #564AE5;
  color: white;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination .disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.pagination .previous,
.pagination .next {
  font-weight: bold;
}

.pagination .previous:hover,
.pagination .next:hover {
  background-color: transparent;
}





@media only screen and (max-width: 768px) {
  .add-item-section {
    padding: 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-group {
    width: 70%;
    margin-bottom: 15px;
  }

  .input-group-brand {
    width: 70%;
    margin-bottom: 15px;
  }
  .input-group-area {
    width: 70%;
    margin-bottom: 15px;
  }
  .input-group-color {
    width: 70%;
    margin-bottom: 15px;
  }
  .input-group-model {
    width: 70%;
    margin-bottom: 15px;
  }

  .input-group label {
    margin-right: 0;
  }


  .input-group-brand input[type="text"] {
    width: 274px;
    /* Outros estilos, se necessário */
  }

  .input-group-color input[type="text"] {
    width: 274px;
    /* Outros estilos, se necessário */
  }

  .input-group-model input[type="text"] {
    width: 274px;
    /* Outros estilos, se necessário */
  }

  .input-group-area input[type="text"] {
    width: 274px;
    /* Outros estilos, se necessário */
  }

  .input-group-material select,
  .input-group-material input[type="text"] {
    height: 40px; /* Define a altura desejada */
    width: 110%;
    margin: 0 35px 0 0;
    border-radius: 4px; /* Adiciona border radius */
  }

  .input-group-material {
    margin: 0 0 0 -15px;
    padding: 0 6px 0 5px;
    display: inline-grid;
    align-items: center;
  }

  .input-group-ambiente {
    margin: 0 0 0 -15px;
    padding: 18px 6px 0 5px;
    display: inline-grid;
    align-items: center;
  }

  .input-group-ambiente select,
  .input-group-ambiente input[type="text"] {
    height: 40px;
    /* width: 112%; */
    width: 110%;
    margin: 0 69px 0 0;
    border-radius: 4px;  }




  .input-group input[type="text"],
  .input-group select {
    width: 100%;
  }

  .generate-remove {
    margin-left: 0;
    text-align: center;
  }

  .generate-pdf-button,
  .generate-remove button {
    width: 100%;
  }

  .paginate-class{
    /* display: contents; */
    margin: 17px 0 0 20%;
    /* justify-content: center;   */
  }

  .auth-button-add {
    width: 51%;
    /* padding: 12px; */
    height: 45px;
    margin: 30px 0 0 1%;
    background-color: #564AE5;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .items-list {
    padding: 0 0 0 0;
    margin: 44px 0 0 -16%;
    overflow-x: auto;
    }

  .items-list th,
  .items-list td {
    padding: 7px; /* Reduz o espaçamento interno das células */
  }


  .filtrogeral-form {
    width: 73%;
    display: flex;
    margin: auto;
    padding: 11% 0 0 0;
  }

  .pagination {
    margin: 0 0 0 15%;
  }
  
  .pagination a {
    color: #4a5568;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .pagination a.active {
    background-color: #564AE5;
    color: white;
  }
  
  .pagination a:hover:not(.active) {
    background-color: #ddd;
  }
  
  .pagination .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
  
  .pagination .previous,
  .pagination .next {
    font-weight: bold;
  }
  
  .pagination .previous:hover,
  .pagination .next:hover {
    background-color: transparent;
  }



  
.form-label {
  margin-right: 10px;
  font-size: 14px;
  color: #4a5568;
  margin-top: 12px;
  padding: 0 0 0 18px;
}

/* Estilos para os selects */
.form-select {
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

/* Estilos para o botão */
.form-button {
  background-color: #564AE5;
  color: #fff;
  border: none;
  margin: 14px 0 33px 36%;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #463ecc;
}

.pdfForm{
  margin: 0 27px 2% 0;
  display: inline-flex;
  padding: 2% 0 0 0;

}

.items-list img {
  margin: 0 0 16px 0;
  max-width: 86px;
}


}










.container-document {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5% 0 0 40%;
  width: 24%;
}

.dropdown-content-pdf {
  display: grid;
  margin: 10px 0 0 16px;
}

.dropdown-content-pdf:hover {
  background-color: #564ae5;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.document-file-text {
  flex: 1; /* Ocupa todo o espaço disponível */
  cursor: pointer;
  color: #3a3a3a; /* Cor do link */
  text-decoration: none;
}

.delet-pdf {
  padding: 5px 10px;
  background-color: #564ae5; /* Cor de fundo vermelha */
  color: #fff; /* Cor do texto branco */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delet-pdf:hover {
  background-color: #564ae5; /* Cor de fundo vermelha mais escura ao passar o mouse */
}


.doc-class {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4f46e5;
  padding: 0 12px 0 18px;
}

.project-name {
  margin: 0;
}

.edit-escopo {
  padding: 0 0 20px 0;
}


.edit-link{
  width: 47%;
  padding: 10px;
  background-color: #564ae5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.project-address {
  margin: 10px 0 25px 0;
  color: #a7a7a7;
  font-size: 14px;
}

.auth-button-1 {
  width: 47%;
  padding: 10px;
  background-color: #564ae5;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.button-trash {
  font-size: 14px;
  text-transform: none;
  color: white;
}

.document-base {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 10px;
}

.input-group-cam {
  margin-top: 10px;
}

.purple-button-pdf {
  background-color: #4f46e5;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.dropdown-container {
  margin-top: 10px;
}

.dropdown-button {
  background-color: #4f46e5;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dropdown-content-pdf {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.dropdown-content-pdf:hover {
  background-color: #9e9e9e61;
  border-radius: 4px;
  color: #4f46e5;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #fff;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #999;
}

.modal-close:hover {
  color: #333;
}

.details-dash {
  background-color: #6257dd;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 0 25px 70%;
}

.delet {
  background-color: #6257dd;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;

  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: -68% 0 203px 83%;
}

.icon-send{
  margin: 4px 0 0 5px;
  display: inline-block;
  width: 21px;
  height: 20px;
  background-image: url('../img/upload.png');
  background-size: contain; /* Ajusta o tamanho do ícone */
  background-repeat: no-repeat;
  vertical-align: middle; /* Alinha verticalmente com o texto */
}

.modal-content {
  margin-top: 20px;
}

.modal-content p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
}

.details-text{
  font-weight: bold;
  margin: 0 0 20px 0;
  font-size: 22px;
color: #7f7f7f;
}

.details {
  background-color: #6257DD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 0 0 8px;

}

.memorial {
  background-color: #6257DD;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    margin: 0 5px 0 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-open-pdf-modal{
  background-color: #6257DD;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 0 0 50px;

}

.icon-folder {
  display: inline-block;
  width: 20px; /* Ajuste conforme necessário */
  height: 20px; /* Ajuste conforme necessário */
  background-image: url('../img/Frame17.png');
  background-size: contain; /* Ajusta o tamanho do ícone */
  background-repeat: no-repeat;
  vertical-align: middle; /* Alinha verticalmente com o texto */
}

.icon-folder-pdf {
  display: inline-block;
  width: 20px; /* Ajuste conforme necessário */
  height: 20px; /* Ajuste conforme necessário */
  background-image: url('../img/Frame17.png');
  background-size: contain; /* Ajusta o tamanho do ícone */
  background-repeat: no-repeat;
  vertical-align: middle; /* Alinha verticalmente com o texto */
}

.icon-trash {
  display: inline-block;
  width: 20px; /* Ajuste conforme necessário */
  height: 20px; /* Ajuste conforme necessário */
  background-image: url('../img/trash.png');
  background-size: contain; /* Ajusta o tamanho do ícone */
  background-repeat: no-repeat;
  vertical-align: middle; /* Alinha verticalmente com o texto */
}



/* .delet-pdf{
  margin: 0 0 14px 89%;
  background-color: #6257DD;
  color: white;
  border: none;
  padding: 2px 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

.modal-content p:first-child {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-content p:last-child {
  margin-bottom: 0;
}

strong {
  font-weight: bold;
}

@media screen and (max-width: 480px) { /* Consulta de mídia para telas de até 480px de largura (tipicamente smartphones) */
  .container-document {
    margin: 5% auto;
    width: 90%; /* Ajustado para ocupar 90% da largura da tela */
    max-width: 90%; /* Define uma largura máxima */
  }

  .dropdown-content-pdf {
    margin: 10px 0;
  }

  .details {
    background-color: #6257DD;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }
  
  .memorial {
    background-color: #6257DD;
      color: white;
      border: none;
      padding: 12px 20px;
      border-radius: 5px;
      margin: 0 5px 0 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }

  .btn-open-pdf-modal{
    background-color: #6257DD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0 0 32px;
    transition: background-color 0.3s ease;
  }

  .modal {
    background-color: #fff;
    width: 98%;
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .delet-pdf{
    background-color: #6257DD;
    color: white;
    /* display: flex; */
    border: none;
    padding: 2px 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

}

/* tailwindAuth.css */

.container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
  }
  
  .auth-container {
    width: 400px;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute; /* Define o posicionamento absoluto */
    top: 50%; /* Posiciona o topo do elemento no meio da página */
    left: 50%; /* Posiciona a esquerda do elemento no meio da página */
    transform: translate(-50%, -50%); /* Move o elemento para o centro */
  }
  
  .auth-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }

  .version-register {
    margin-left: 47%;
    font-size: 10px;
    /* margin-top: -7%; */
  }
  
  .auth-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .auth-button {
    width: 100%;
    padding: 12px;
    background-color: #564AE5;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .flex-shrink-0 {
  
  }
  
  .nex {
    width: 18%;
    padding: 40px;
    position: absolute; /* Define o posicionamento absoluto */
    top: 12%;
    left: 51%; /* Posiciona a esquerda do elemento no meio da página */
    transform: translate(-50%, -50%); /* Move o elemento para o centro */
  }

  .version-register {
    margin-left: 47%;
    /* font-size: 10px; */
    /* margin-top: -32%;   */
  }
  
  .auth-button:hover {
    background-color: #7b74cd;
  }
  
  .auth-link {
    display: block;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #333;
    text-decoration: none;
  }
  
  .auth-link:hover {
    text-decoration: underline;
  }

  .eye-icon {
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: grey;
  }

  .password-strength-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #4caf50; /* Cor da barra para uma senha forte */
    transition: width 0.3s ease;
  }
  
  
  
  /* Media query para telas menores */
  @media screen and (max-width: 768px) {
    .container {
      display: flex;
      justify-content: center; /* Centraliza horizontalmente */
      align-items: center; /* Centraliza verticalmente */
      height: 100vh; /* Faz o contêiner ocupar toda a altura da tela */
    }
    
    .auth-container {
      width: 90%;
      padding: 40px;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: absolute; /* Define o posicionamento absoluto */
      top: 54%;
      left: 50%; /* Posiciona a esquerda do elemento no meio da página */
      transform: translate(-50%, -50%); /* Move o elemento para o centro */
    }
    
    .auth-heading {
      font-size: 24px;
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
    }
    
    .auth-input {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
    }
    
    .auth-button {
      width: 100%;
      padding: 12px;
      background-color: #564AE5;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
    
    .auth-button:hover {
      background-color: #7b74cd;
    }
    
    .auth-link {
      display: block;
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      color: #333;
      text-decoration: none;
    }
    
    .auth-link:hover {
      text-decoration: underline;
    }
  
    .nex {
      width: 80%;
      padding: 40px;
      position: absolute; /* Define o posicionamento absoluto */
      top: 12%; /* Posiciona o topo do elemento no meio da página */
      left: 57%; /* Posiciona a esquerda do elemento no meio da página */
      transform: translate(-50%, -50%); /* Move o elemento para o centro */
    }
  
    .version-register {
      margin-left: 36%;
      font-size: 10px;
      /* margin-top: -32%;   */
    }
  }
  
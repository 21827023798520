.my-component {
    padding: 20px;
  }
  
  .dropdown-toggle {
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-item {
    color: #333;
    padding: 10px 20px;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }

  .teste {
    inset: 0px auto auto 0px;
    display: grid;
    margin: 0 0 -28rem 0;
    background-color: #fff;
  }

  .dropdown-content-pdf{
    display: grid;
    margin: 10px 0 0 16px;
}

.button-send{
    background-color: #6257DD;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
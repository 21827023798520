/* Arquivo Navbar.css (ou adicione diretamente ao seu arquivo .jsx) */
.avatar-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .mr-1 {
    /* color: pink; */
    margin: 0 0 0 14px;

}

.form-container {
  max-width: 57%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  /* background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  /* font-weight: bold; */
  margin-bottom: 5px;
}

.notification {
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
}

.input-error-message {
  color: red;
}


.notification.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}


input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.form-group-escopo{
  width: 204%;
  margin-bottom: 20px;

}

.btn-submit {
  background-color: #564AE5;
  color: white;
  border: none;
  margin: 25% 0 0 -14%;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button {
  background-color: #564AE5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  /* background-color: #a79fff; */
}

/* Estilo para os títulos "CLIENTE" e "DADOS DA OBRA" */
h3 {
  margin-top: 20px;
  margin-bottom: 50px;
  font-weight: bold;


}

/* Estilo para o layout lado a lado */
.form-group-container {
  display: flex;
  justify-content: space-between;
}

.form-date-container {
  display: flex;
  margin: 40px 0 0 0;
}

.form-group-date {
  margin-top: 30px;
}

.form-group-dateend {
  margin-top: 30px;
  margin-left: 20px; /* Ajuste conforme necessário */
}

.version-nav{
  color: white;
  font-size: 10px;
}

.class-logo{
  width: 8px;
}

.form-group-date {
  margin-top: 54px;
  display: inline-block;
}

.form-group-status {
  margin-top: 6px;
  /* display: flex;
  justify-content: space-between; */
}

.form-group-dateend {
  margin-top: 54px;
  margin-left: 20px; /* Adicione margem esquerda para separar os campos */
  display: inline-block;
}


.form-group-obra {
  margin: 54px 0 0 0;
}

.form-group-container .form-group {
  flex-basis: calc(50% - 10px);
}

/* Estilo para ajustar a largura dos campos */
.form-group-container .form-group input[type="text"],
.form-group-container .form-group select,
.form-group-container .form-group textarea {
  width: 100%;
}

/* Estilo para tornar o formulário responsivo */
@media screen and (max-width: 600px) {
  .form-container {
    max-width: 95%;
    padding: 10px;
    margin: 0 0 0 0;
  
  }

  .btn-submit {
    justify-content: center;
    display: flex;
    width: 62%;
    margin: auto;
    /* margin: 20px auto 0; */
    margin-bottom: 31px;
    }

  /* Ajustes adicionais conforme necessário */
  .form-group-date,
  .form-group-dateend,
  .form-group-escopo,
  .form-group-status,
  .form-group-obra {
    margin-top: 20px; /* Reduzindo a margem superior entre os grupos */
  }

  /* Ajustando o tamanho do logo */
  .class-logo {
    width: 4px; /* Reduzindo o tamanho do logo para telas menores */
  }
}
